import React from "react";
import "./index.less";
import { Input } from "@arco-design/web-react";
import AnnaButton from "src/components/button";

const AboutLocation = () => {
  return (
    <div className="about-location">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: 42,
          marginBottom: 72,
        }}
      >
        <div
          style={{
            flexShrink: 0,
            flexBasis: "350px",
            fontSize: 48,
            fontWeight: 700,
          }}
        >
          Location
        </div>
        <div style={{ flex: 1 }}>
          <div style={{ fontSize: 20, fontWeight: 700, marginBottom: 16 }}>
            Anna Investment Group
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flex: 1 }}>
              <div style={{ marginBottom: 8 }}>Representative:</div>
              <div style={{ marginBottom: 8 }}>Establish:</div>
              <div style={{ marginBottom: 8 }}>TEL:</div>
              <div style={{ marginBottom: 8 }}>E-mail:</div>
              <div>Headquarter:</div>
            </div>
            <div style={{ flex: 1 }}>
              <div style={{ marginBottom: 8 }}>Shiyu Wang</div>
              <div style={{ marginBottom: 8 }}>August 2022</div>
              <div style={{ marginBottom: 8 }}>03-6761-3640</div>
              <div style={{ marginBottom: 8 }}>info@annagroup</div>
              <div>
                〒104-0051 <br />
                2502 1 Chome-11-9 Tsukuda, <br /> Chuo City, Tokyo
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.3111621762814!2d139.7821031513605!3d35.66933953813309!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018896f40b8f277%3A0xebd6372f44bfd679!2z44CSMTA0LTAwNTEg5p2x5Lqs6YO95Lit5aSu5Yy65L2D77yR5LiB55uu77yR77yR4oiS77yZ!5e0!3m2!1sja!2sjp!4v1669792124240!5m2!1sja!2sjp"
                width="300"
                height="150"
                style={{ border: 0 }}
                allowFullScreen={false}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ flexShrink: 0, flexBasis: "350px" }}>
          <div style={{ fontSize: 48, fontWeight: 700 }}>Get in Touch</div>
          <div style={{ marginBottom: 32 }}>
            Have a question or a project in mind? I'd love to hear from you.
            Let's chat and make something amazing together.
          </div>
          <div
            style={{
              marginTop: 32,
              display: "flex",
              alignItems: "center",
              gap: 32,
            }}
          >
            <img src={require("./icon-01.png")} alt="" />
            <span>+1205 5872 321</span>
          </div>
          <div
            style={{
              marginTop: 32,
              display: "flex",
              alignItems: "center",
              gap: 32,
            }}
          >
            <img src={require("./icon-02.png")} alt="" />
            <span>contact@sarajonesdesign.com</span>
          </div>
          <div
            style={{
              marginTop: 32,
              display: "flex",
              alignItems: "center",
              gap: 32,
            }}
          >
            <img src={require("./icon-03.png")} alt="" />
            <span>
              1234 Design Street,Creativeville, <br /> Webland,Imaginary State,
              98765
            </span>
          </div>
          <div style={{ display: "flex", gap: 24, marginTop: 72 }}>
            <img src={require("./icon-04.png")} alt="" />
            <img src={require("./icon-05.png")} alt="" />
            <img src={require("./icon-06.png")} alt="" />
            <img src={require("./icon-07.png")} alt="" />
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <Input
            placeholder="First name"
            style={{ height: 50, marginBottom: 24 }}
          />
          <Input placeholder="Email" style={{ height: 50, marginBottom: 24 }} />
          <Input
            placeholder="Phone number"
            style={{ height: 50, marginBottom: 24 }}
          />
          <Input.TextArea
            placeholder="Your message"
            style={{ height: 165, marginBottom: 24 }}
          />
          <AnnaButton
            style={{
              background: "#1F1F1F",
              color: "white",
              display: "inline-block",
            }}
          >
            Send Message
          </AnnaButton>
        </div>
      </div>
    </div>
  );
};

export default AboutLocation;
