import React, { FC } from "react";
import "./index.less";

interface Props {}

const CaseEnterprise: FC<Props> = () => {
  return (
    <div className="case-content">
      <div className="case-content-item">
        <div className="case-content-item-left">
          <div className="case-content-item-desc">
            Impact of Customs Clearance Efficiency
          </div>
          <div className="case-content-item-title">
            Intelligent systems and efficient workflows reduced processing time
            by 40%
          </div>
          <div className="case-content-item-subtitle">Background</div>
          <div>
            The client for this project is a company specializing in
            cross-border logistics and customs clearance. Facing the challenges
            of technological upgrades in the industry and opportunities for
            cross-border business growth, the company is committed to building
            modular and efficient logistics solutions. It aims to transition
            from a business-oriented company to SaaS and PaaS models, ultimately
            creating a platform-based solution to empower more enterprises.
          </div>
          <div className="case-content-item-subtitle">
            Technological Upgrades
          </div>
          <div>
            By upgrading its system architecture, the client decoupled various
            functional modules, enabling flexible modular combinations to meet
            diverse business scenarios. This design provides the platform with
            high adaptability, allowing for customized solutions based on
            specific business needs. The project also introduced features such
            as automated warehouse management, intelligent route planning,
            delivery task allocation, and a smart customer service assistant.
            These innovations significantly reduced labor costs while improving
            operational efficiency and accuracy.
          </div>
          <div className="case-content-item-subtitle">Business Expansion</div>
          <div>
            The client successfully transitioned from a basic logistics service
            provider to a PaaS platform supporting third-party integrations.
            Through open API interfaces, the platform now supports multi-tenant
            access for companies such as YTO, SF Express, and Nuoyu, enabling
            third-party enterprises to seamlessly connect with logistics
            services. This evolution has greatly expanded the platform’s service
            scope and influence.
          </div>
          <div className="case-content-item-subtitle">
            Operational Efficiency Improvements
          </div>
          <div>
            The platform leverages automation and intelligent technologies to
            enhance overall operational efficiency. Automated warehouse
            management has enabled precise inventory operations, route
            optimization algorithms have improved delivery punctuality, and the
            smart customer service assistant has accelerated customer response
            times. Data from actual operations show a 40% reduction in customs
            clearance time and a 25% decrease in delivery errors, allowing the
            client to provide highly competitive cross-border logistics
            services, including next-day (T+1) delivery.
          </div>
          <div className="case-content-item-subtitle">
            Data Analysis and Decision Support
          </div>
          <div>
            The platform incorporates data analysis modules to monitor key
            business metrics in real time, offering management actionable
            insights. Reporting systems identify potential issues and use data
            mining techniques to inform future business strategies, helping the
            client maintain a competitive edge in the market.
            <div>Milestones Achieved</div>
            <ul>
              <li>
                2023: Completed the development of core infrastructure and
                functions, including order management, warehouse management, and
                transportation management, laying a solid technical foundation.
              </li>
              <li>
                2024: Successfully transitioned to a PaaS platform, achieving
                modular services and multi-tenant access, enhancing user
                experience and optimizing functionality for greater customer
                retention.
              </li>
              <li>
                2025: Adopted advanced technologies like IoT, unmanned
                warehouses, and autonomous delivery. Expanded into diversified
                areas such as supply chain finance and cross-border logistics,
                entering Southeast Asian and U.S. markets.
              </li>
            </ul>
          </div>
          <div className="case-content-item-subtitle">Impact</div>
          <div>
            The project has helped the client achieve efficient customs
            clearance for cross-border logistics, supporting integrations with
            e-commerce platforms such as CAINIAO and TEMU, and facilitating
            rapid clearance of Chinese export goods to Japan. Additionally, the
            platform has established deep collaborations with major Japanese
            logistics companies, including SAGAWA, YAMATO, and Japan Post. It
            now handles a significant portion of logistics demand between China
            and Japan, creating substantial commercial value and earning greater
            market share and customer trust in the global cross-border logistics
            industry.
          </div>
          <div style={{ marginTop: 24 }}>Customs Clearance Efficiency: </div>
          <div>
            Intelligent systems and efficient workflows reduced customs
            processing time by approximately 40%, enabling near-instantaneous
            clearance.
          </div>
          <div style={{ marginTop: 24 }}>Error Rate Reduction:</div>
          <div>
            Accurate tracking and data management reduced delivery errors by
            25%, significantly improving logistical precision.
          </div>
          <div style={{ marginTop: 24 }}>E-commerce Integration: </div>
          <div>
            Successfully integrated with leading cross-border e-commerce
            platforms such as CAINIAO and TEMU, covering nearly all Chinese
            export goods to Japan and providing end-to-end customs and delivery
            services.
          </div>
          <div style={{ marginTop: 24 }}>Local Logistics Partnerships:</div>
          <div>
            Deep collaborations with SAGAWA, YAMATO, and Japan Post in Japan
            ensure seamless transitions from customs clearance to delivery.
          </div>
          <div style={{ marginTop: 24 }}>Fast Delivery:</div>
          <div>
            Achieved next-day (T+1) delivery for export goods, offering
            industry-leading speed and enhancing customer satisfaction and
            end-user experience.
          </div>
        </div>
        <img
          src={require("./img-01.png")}
          alt="img-01"
          style={{ position: "sticky", top: 100 }}
        />
      </div>
      <div className="case-content-item">
        <div className="case-content-item-left">
          <div className="case-content-item-desc">
            Customer Satisfaction Improvement
          </div>
          <div className="case-content-item-title">
            Emotional and multilingual virtual assistants boosted customer
            satisfaction by 35%.
          </div>
          <div className="case-content-item-subtitle">Background</div>
          <div>
            SoftBank sought to integrate virtual assistant technology into its
            customer service system to reduce frontline staff workload and
            enhance customer experience. By implementing Anna Group's virtual
            customer service solution, SoftBank successfully launched a
            multilingual virtual assistant system capable of efficiently
            handling customer inquiries, providing product recommendations, and
            recognizing emotional cues.
          </div>
          <div className="case-content-item-subtitle">
            Project Requirements and Challenges
          </div>
          <div>
            <div>Multilingual Support: </div>
            <div>
              SoftBank’s diverse customer base required support for multiple
              languages, including Japanese, English, Chinese, and Korean, to
              ensure smooth communication and accessibility for all users.
            </div>
            <div style={{ marginTop: 24 }}>
              Intelligent Responses and Emotion Recognition:
            </div>
            <div>
              The virtual assistant needed to comprehend user inquiries, deliver
              real-time personalized responses, and use facial expressions and
              tone to convey emotions, enhancing the overall user experience.
            </div>
            <div style={{ marginTop: 24 }}>
              Data Analysis and Visualization:
            </div>
            <div>
              SoftBank required regular insights into common customer queries,
              satisfaction levels, and interaction frequency to refine service
              processes and strategies continuously.
            </div>
          </div>
          <div className="case-content-item-subtitle">Solution</div>
          <div>
            <div>
              Anna Group provided a customized virtual assistant solution,
              offering end-to-end support from system architecture to user
              interface design:
            </div>
            <div style={{ marginTop: 24 }}>Multilingual Interaction:</div>
            <div>
              Users could select their preferred language on the start screen,
              and the system seamlessly switched to the appropriate language
              mode, ensuring accurate and smooth communication.
            </div>
            <div style={{ marginTop: 24 }}>
              Intelligent Responses with Emotional Expressions:
            </div>
            <div>
              The virtual assistant delivered standardized answers while
              enriching interactions with emotional expressions and voice
              modulation. Using APIs, the system dynamically adjusted the
              virtual assistant’s role to suit specific scenarios, such as
              customer support, medical consultations, or child engagement.
            </div>
            <div style={{ marginTop: 24 }}>
              Data Management and Visualization:
            </div>
            <div>
              The backend system automatically collected and organized user
              interaction data, generating insightful visualized reports to help
              SoftBank analyze customer needs and optimize service offerings.
            </div>
          </div>
          <div className="case-content-item-subtitle">
            Operational Efficiency Improvements
          </div>
          <div>
            The platform leverages automation and intelligent technologies to
            enhance overall operational efficiency. Automated warehouse
            management has enabled precise inventory operations, route
            optimization algorithms have improved delivery punctuality, and the
            smart customer service assistant has accelerated customer response
            times. Data from actual operations show a 40% reduction in customs
            clearance time and a 25% decrease in delivery errors, allowing the
            client to provide highly competitive cross-border logistics
            services, including next-day (T+1) delivery.
          </div>
          <div className="case-content-item-subtitle">
            Data Analysis and Decision Support
          </div>
          <div>
            <div>
              The platform incorporates data analysis modules to monitor key
              business metrics in real time, offering management actionable
              insights. Reporting systems identify potential issues and use data
              mining techniques to inform future business strategies, helping
              the client maintain a competitive edge in the market.
            </div>
            <div>Milestones Achieved</div>
            <ul>
              <li>
                2023: Completed the development of core infrastructure and
                functions, including order management, warehouse management, and
                transportation management, laying a solid technical foundation.
              </li>
              <li>
                2024: Successfully transitioned to a PaaS platform, achieving
                modular services and multi-tenant access, enhancing user
                experience and optimizing functionality for greater customer
                retention.
              </li>
              <li>
                2025: Adopted advanced technologies like IoT, unmanned
                warehouses, and autonomous delivery. Expanded into diversified
                areas such as supply chain finance and cross-border logistics,
                entering Southeast Asian and U.S. markets.
              </li>
            </ul>
          </div>
          <div className="case-content-item-subtitle">Impact</div>
          <div>
            <div>Results and Impact</div>
            <ul>
              <li>
                Reduced Employee Workload: The virtual assistant managed 80% of
                common customer inquiries, significantly easing the workload of
                human agents and lowering operational costs.
              </li>
              <li>
                Enhanced Customer Satisfaction: Emotional interaction features
                and multilingual support increased customer satisfaction by 35%.
                The virtual assistant’s ability to seamlessly adapt to different
                scenarios ensured a smooth user experience, from product
                inquiries to service guidance.
              </li>
              <li>
                Data-Driven Optimization: Monthly reports on user interactions
                revealed a 50% improvement in resolving common queries. SoftBank
                gained timely insights into customer needs, enabling swift
                adjustments to service strategies and continuous service
                enhancements.
              </li>
            </ul>
          </div>
          <div className="case-content-item-subtitle">Customer Feedback</div>
          <div>
            “Anna Group's virtual assistant technology has brought innovative
            upgrades to our customer service. The virtual assistant not only
            improved the user experience but also significantly reduced the
            workload for our frontline employees. We are highly satisfied with
            this collaboration, which has provided invaluable support for
            SoftBank’s service modernization.”
          </div>
        </div>
        <img
          src={require("./img-02.png")}
          alt="img-02"
          style={{ position: "sticky", top: 100 }}
        />
      </div>
    </div>
  );
};

export default CaseEnterprise;
