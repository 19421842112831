import React, { FC } from "react";
import useWindowSize from "src/hooks/useWindowSize";
import styles from "./index.module.less";
import AnnaButton from "src/components/button";

interface Props {}

const CaseBanner: FC<Props> = () => {
  const { isMobile } = useWindowSize();

  return (
    <section
      className={styles["case-banner"]}
      style={{ padding: isMobile ? "30px 20px" : "30px 160px" }}
    >
      <div className={styles["banner-content"]}>
        <div className={styles["banner-title"]}>You are in great company</div>
        <div className={styles["banner-subtitle"]}>
          Meet the
          <br />
          companies who
          <br />
          we work with
        </div>
        <div className={styles["banner-desc"]}>
          Anna Global Tech LLC empowers businesses with world-class innovation
          <br />
          from Japan. Discover how we drive success with cutting-edge technology
          <br />
          and customer-focused solutions.
        </div>
        <div className={styles["banner-btns"]}>
          <AnnaButton type="primary">
            View all Stories
            <img src={require("./bottom-arrow.png")} alt="" />
          </AnnaButton>
        </div>
      </div>
      <div>
        <img src={require("./top.png")} alt="" />
        <div style={{ display: "flex", gap: 24, marginTop: 16 }}>
          <img src={require("./left.png")} alt="" />
          <img src={require("./right.png")} alt="" />
        </div>
      </div>
    </section>
  );
};

export default CaseBanner;
