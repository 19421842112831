import React, { FC } from "react";
import useWindowSize from "src/hooks/useWindowSize";

import AboutBanner from "./components/about-banner";
import AboutVersion from "./components/about-version";
import AboutTeam from "./components/about-team";
import AboutLocation from "./components/about-location";

interface Props {}

const About: FC<Props> = () => {
  const { isMobile } = useWindowSize();
  return (
    <div>
      <AboutBanner />
      <section style={{ padding: isMobile ? "0 20px" : "0 160px" }}>
        <AboutVersion />
        <AboutTeam />
        <AboutLocation />
      </section>
    </div>
  );
};

export default About;
