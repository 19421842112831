import React, { FC } from "react";
import "./index.less";

interface Props {}

const SolutionsIT: FC<Props> = () => {
  return (
    <section className="solutions-it">
      <div className="solutions-scope">Scope1</div>
      <div className="solutions-title">IT System Development</div>
      <div className="solutions-sub-title">
        From Vision to Reality: Tailored IT Solutions Designed with Precision.
      </div>
      <div className="solutions-content">
        <div className="solutions-content-left">
          <div className="solutions-content-left-top">
            <div className="solutions-content-item">
              <img
                className="solutions-content-item-icon"
                src={require("./icon-01.png")}
                alt="icon"
              />
              <div>
                <div className="solutions-content-item-step">Step 1</div>
                <div className="solutions-content-item-title">
                  Requirement Analysis
                </div>
                <div>
                  Deeply understand the client’s business needs and define clear
                  system objectives.
                </div>
              </div>
            </div>
            <div className="solutions-content-item">
              <img
                className="solutions-content-item-icon"
                src={require("./icon-02.png")}
                alt="icon"
              />
              <div>
                <div className="solutions-content-item-step">Step 2</div>
                <div className="solutions-content-item-title">
                  Solution Design
                </div>
                <div>
                  Develop a complete system architecture based on requirements,
                  create a detailed plan, and present it to the client via a
                  professional PPT.
                </div>
              </div>
            </div>
            <div className="solutions-content-item">
              <img
                className="solutions-content-item-icon"
                src={require("./icon-03.png")}
                alt="icon"
              />
              <div>
                <div className="solutions-content-item-step">Step 3</div>
                <div className="solutions-content-item-title">
                  Plan Confirmation
                </div>
                <div>
                  At this stage, the client incurs no costs. Development begins
                  only after the design plan, timeline, and budget are confirmed
                  through communication.
                </div>
              </div>
            </div>
            <div className="solutions-content-item">
              <img
                className="solutions-content-item-icon"
                src={require("./icon-04.png")}
                alt="icon"
              />
              <div>
                <div className="solutions-content-item-step">Step 4</div>
                <div className="solutions-content-item-title">
                  System Development:
                </div>
                <div>
                  Strictly follow the project timeline, with milestone reviews
                  involving the client to ensure the project consistently meets
                  expectations.
                </div>
              </div>
            </div>
            <div className="solutions-content-item">
              <img
                className="solutions-content-item-icon"
                src={require("./icon-05.png")}
                alt="icon"
              />
              <div>
                <div className="solutions-content-item-step">Step 5</div>
                <div className="solutions-content-item-title">
                  Testing and Delivery
                </div>
                <div>
                  After development, provide a system testing report, user
                  manual, and handover training for smooth client adoption.
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>Click to Save your Time </div>
            <div className="solutions-content-btn">
              <span>Contact Us</span>
              <img src={require("./white-arrow.png")} alt="icon" />
            </div>
          </div>
        </div>
        <div>
          <div></div>
          <img src={require("./img-01.jpg")} alt="" />
        </div>
      </div>
    </section>
  );
};

export default SolutionsIT;
