const i18n = {
  "en-US": {
    "footer.about": "About",
    "footer.solutions": "Solutions",
    "footer.case": "Case Studies",
    "footer.price": "Price",
  },
  "zh-CN": {
    "footer.about": "关于我们",
    "footer.solutions": "解决方案",
    "footer.case": "导入案例",
    "footer.price": "价格信息",
  },
  "ja-JP": {
    "footer.about": "About",
    "footer.solutions": "Solutions",
    "footer.case": "Case Study",
    "footer.price": "Price",
  },
};

export default i18n;
