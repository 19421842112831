import React, { FC, useState } from "react";
import useWindowSize from "src/hooks/useWindowSize";
import "./index.less";

import CaseBanner from "./components/case-banner";
import btnBlack from "./btn-black.png";
import btnGray from "./btn-gray.png";
import CaseEnterprise from "./components/case-enterprise";
import CaseAgency from "./components/case-agency";

interface Props {}

const Case: FC<Props> = () => {
  const { isMobile } = useWindowSize();
  const [active, setActive] = useState("Enterprise");
  return (
    <div>
      <CaseBanner />
      <section style={{ padding: isMobile ? "0 20px" : "0 160px" }}>
        <div className="case-direction">
          <div style={{ textAlign: "center", paddingTop: 90 }}>
            <div className="case-direction-title">
              Explore <br /> Customer Stories
            </div>
            <div className="case-direction-desc">
              Learn how Anna Group Tech empowers business with cutting-edge
              technology - professional, responsive, and globally.
            </div>
            <div className="case-direction-btn">
              <div
                onClick={() => setActive("Enterprise")}
                className={`case-direction-btn-item ${
                  active === "Enterprise" ? "active" : ""
                }`}
              >
                Enterprise{" "}
                <img
                  src={active === "Enterprise" ? btnBlack : btnGray}
                  alt=""
                />
              </div>
              <div
                onClick={() => setActive("Agency")}
                className={`case-direction-btn-item ${
                  active === "Agency" ? "active" : ""
                }`}
              >
                Agency{" "}
                <img src={active === "Agency" ? btnBlack : btnGray} alt="" />
              </div>
            </div>
          </div>
        </div>
        {active === "Enterprise" ? <CaseEnterprise /> : <CaseAgency />}
      </section>
    </div>
  );
};

export default Case;
