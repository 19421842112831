const i18n = {
  "en-US": {
    "header.home": "Home",
    "header.solutions": "Solutions",
    "header.case": "Case Study",
    "header.about": "About Us",
    "header.price": "Pricing",
  },
  "zh-CN": {
    "header.home": "主页",
    "header.solutions": "解决方案",
    "header.case": "导入案例",
    "header.about": "公司信息",
    "header.price": "价格信息",
  },
  "ja-JP": {
    "header.home": "ホーム",
    "header.solutions": "解决方案",
    "header.case": "導入事例",
    "header.about": "企業情報",
    "header.price": "价格信息",
  },
};

export default i18n;
