import React from "react";
import "./index.less";

const AboutTeam = () => {
  return (
    <div className="about-team">
      <div style={{ padding: "80px 0" }}>
        <div style={{ fontSize: 16 }}>OUR TEAM</div>
        <div style={{ fontSize: 48, fontWeight: 600 }}>
          We will continue to evolve and work <br /> as a team to provide you
          with the <br />
          best possible solutions.
        </div>
        <div className="team-item">
          <div
            style={{
              fontSize: 48,
              fontWeight: 600,
              position: "sticky",
              top: "60px",
            }}
          >
            Staff
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexWrap: "wrap",
              gap: "2%",
              maxWidth: 800,
            }}
          >
            <div style={{ flexBasis: "31%" }}>
              <img src={require("./img-01.png")} alt="" />
              <div style={{ fontSize: 20, fontWeight: 700 }}>
                Shiyu Wang | Software Engineer
              </div>
              <div>
                He earned his master’s degree from the University of Michigan in
                2015 and has since worked as a software engineer and technical
                leader at Cisco Systems, Huawei Technologies in the U.S., and
                ByteDance in China.
              </div>
            </div>
            <div style={{ flexBasis: "31%" }}>
              <img src={require("./img-04.png")} alt="" />
              <div style={{ fontSize: 20, fontWeight: 700 }}>
                Dong Li | Software Engineer
              </div>
              <div>
                He has worked at top internet companies such as Meituan and
                Alibaba and is now a senior engineer at ByteDance. In 2023, he
                presented on front-end development at the QCon conference in
                Beijing.
              </div>
            </div>
            <div style={{ flexBasis: "31%" }}>
              <img src={require("./img-07.png")} alt="" />
              <div style={{ fontSize: 20, fontWeight: 700 }}>
                Xiaoliang Yu | Software Engineer
              </div>
              <div>
                With a focus on cross-border transactions, he previously worked
                at a major internet company in Singapore. He has extensive B2C
                and B2B experience and is currently a senior software engineer
                at ByteDance.
              </div>
            </div>
            <div style={{ flexBasis: "31%" }}>
              <img src={require("./img-02.png")} alt="" />
              <div style={{ fontSize: 20, fontWeight: 700 }}>
                Jian Xu | Software Engineer
              </div>
              <div>
                He previously worked at leading companies such as Three
                Squirrels and Aihuishou and is now a senior software engineer at
                ByteDance, specializing in scalable system design and
                optimization.
              </div>
            </div>
            <div style={{ flexBasis: "31%" }}>
              <img src={require("./img-05.png")} alt="" />
              <div style={{ fontSize: 20, fontWeight: 700 }}>
                Qiang Tu | Software Engineer
              </div>
              <div>
                Formerly at Baidu and ByteDance, he is now a Senior Software
                Engineer at Tencent and an independent game developer. A Global
                Game Jam winner, he represents TC39, driving ECMAScript
                proposals.
              </div>
            </div>
            <div style={{ flexBasis: "31%" }}>
              <img src={require("./img-08.png")} alt="" />
              <div style={{ fontSize: 20, fontWeight: 700 }}>
                Minhui Zheng | Software Engineer
              </div>
              <div>
                He earned his master’s degree from the University at Buffalo in
                2015 and has worked at Huawei, DiDi, and SmartNews. Currently,
                he is a senior engineer at an international bank, focusing on
                cloud-based systems.
              </div>
            </div>
            <div style={{ flexBasis: "31%" }}>
              <img src={require("./img-03.png")} alt="" />
              <div style={{ fontSize: 20, fontWeight: 700 }}>
                Leo Fu | Software Engineer
              </div>
              <div>
                A senior software engineer at ByteDance and TubiTV, he has over
                seven years of experience specializing in web media
                technologies. He co-founded xgplayer, contributes to W3C, and is
                a two-time hackathon winner.
              </div>
            </div>
            <div style={{ flexBasis: "31%" }}>
              <img src={require("./img-06.png")} alt="" />
              <div style={{ fontSize: 20, fontWeight: 700 }}>
                Thomas Yang | Software Engineer
              </div>
              <div>
                Currently a Senior Full Stack Engineer at ByteDance and Binance,
                he has seven years of experience. He focuses on efficient
                applications for Pro users and applies insights from tech
                conferences to improve business tools.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTeam;
