import { useContext, useState, useEffect } from "react";
import { Select, Menu, Button, Layout } from "@arco-design/web-react";
import { IconLanguage } from "@arco-design/web-react/icon";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "src/context";
import logo from "src/assets/pngs/logo.png";
import styles from "./index.module.less";
import useLocale from "src/utils/useLocale";
import { IconMenuFold, IconMenuUnfold } from "@arco-design/web-react/icon";
import locale from "./locale";
import useWindowSize from "src/hooks/useWindowSize";
import { gsap } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

const MenuItem = Menu.Item;

const hideHeaderRoutes: { path: string }[] = [];

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const route = matchRoutes(hideHeaderRoutes, location);
  const { setLang, lang, theme, setTheme } = useContext(GlobalContext);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const t = useLocale(locale);
  const [collapse, setCollapse] = useState(true);
  const { isMobile } = useWindowSize();

  useEffect(() => {
    if (location?.pathname) {
      setSelectedKeys([location?.pathname]);
    }
  }, [location?.pathname]);

  useEffect(() => {
    if (isMobile) {
      setCollapse(true);
    } else {
      setCollapse(false);
    }
  }, [isMobile]);

  const goToSlide = (index: number) => {
    gsap.to(window, {
      duration: 1,
      scrollTo: { y: "#section" + index, offsetY: 64 },
    });
  };

  const handleMenuClick = (key: string) => {
    if (isMobile) {
      setCollapse(true);
    } else {
      setCollapse(false);
    }
    navigate(key);
    setSelectedKeys([key]);
  };

  return !route ? (
    <Layout.Header className={styles["navbar"]}>
      {isMobile ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "64px",
            height: "100%",
            flexShrink: 0,
          }}
        >
          <Button
            style={{
              width: "100%",
              height: "100%",
            }}
            type="primary"
            onClick={() => setCollapse(!collapse)}
          >
            {collapse ? <IconMenuUnfold /> : <IconMenuFold />}
          </Button>
        </div>
      ) : null}
      <div
        className={styles["left"]}
        style={{ marginLeft: isMobile ? 0 : "10vw" }}
        onClick={() => {
          navigate("/");
        }}
      >
        <div
          style={{ paddingLeft: isMobile ? 20 : 0 }}
          className={styles["logo"]}
        >
          <img
            style={{
              width: "40px",
              objectFit: "cover",
              overflow: "hidden",
            }}
            alt="Anna"
            src={logo}
          />
        </div>
        {isMobile ? null : (
          <div className={styles["logo-text"]}>
            <span className={styles["logo-span"]}>Anna Group</span>
          </div>
        )}
      </div>
      <div className={styles["menu"]}>
        <Menu
          style={{ display: collapse ? "none" : "inherit" }}
          className={isMobile ? styles["mobile-menu"] : ""}
          mode={isMobile ? "vertical" : "horizontal"}
          collapse={collapse}
          selectedKeys={selectedKeys}
        >
          <MenuItem
            key="/solutions"
            onClick={() => {
              handleMenuClick("/solutions");
            }}
          >
            {t["header.solutions"]}
          </MenuItem>
          <MenuItem
            key="/case"
            onClick={() => {
              handleMenuClick("/case");
            }}
          >
            {t["header.case"]}
          </MenuItem>
          <MenuItem
            key="/about"
            onClick={() => {
              handleMenuClick("/about");
            }}
          >
            {t["header.about"]}
          </MenuItem>
          <MenuItem
            key="/price"
            onClick={() => {
              handleMenuClick("/price");
            }}
          >
            {t["header.price"]}
          </MenuItem>
        </Menu>
      </div>

      <div
        className={styles["right"]}
        style={{ marginRight: isMobile ? 0 : "10vw" }}
      >
        <div className={styles["btn-container"]}>
          <Select
            triggerElement={
              <Button
                icon={<IconLanguage />}
                shape="circle"
                type="secondary"
                className={styles["icon-button"]}
              />
            }
            options={[
              { label: "日本語", value: "ja-JP" },
              { label: "English", value: "en-US" },
              { label: "中文", value: "zh-CN" },
            ]}
            triggerProps={{
              autoAlignPopupWidth: false,
              autoAlignPopupMinWidth: true,
              position: "br",
            }}
            trigger="hover"
            onChange={(value) => {
              setLang(value);
            }}
          ></Select>
        </div>
      </div>
    </Layout.Header>
  ) : null;
};

export default Header;
