import { Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Layout, Spin } from "@arco-design/web-react";
import Header from "./header-v2/index";
import Footer from "./footer-v2/index";
import styles from "./index.module.less";
import lazyload from "./lazyload";
import Solutions from "src/pages/solutions";
import Case from "src/pages/case";
import About from "src/pages/about";
import Price from "src/pages/price";

const Home = lazyload(() => import("src/pages/home-v2"));

const Content = Layout.Content;

const PageLayout = () => {
  return (
    <Layout className={styles["layout"]}>
      <BrowserRouter>
        <Layout>
          <Header />
          <Content className={styles["layout-content"]}>
            <Suspense fallback={<Spin dot />}>
              <Routes>
                <Route key="/" path="/" element={<Home />} />
                <Route
                  key="/solutions"
                  path="/solutions"
                  element={<Solutions />}
                />
                <Route key="/case" path="/case" element={<Case />} />
                <Route key="/about" path="/about" element={<About />} />
                <Route key="/price" path="/price" element={<Price />} />
                <Route key="*" path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Suspense>
          </Content>
          <Footer />
        </Layout>
      </BrowserRouter>
    </Layout>
  );
};

export default PageLayout;
