import React, { FC } from "react";
import "./index.less";

interface Props {}

const SolutionsResume: FC<Props> = () => {
  return (
    <section className="solutions-resume">
      <div className="solutions-scope">Scope3</div>
      <div className="solutions-title">
        Your Personal Career Coach, Powered by AI.
      </div>
      <div className="solutions-sub-title">
        Level Up Your Career for Just $19.99/Month.
      </div>
      <div className="solutions-content">
        <div className="solutions-content-left">
          <div className="solutions-content-left-top">
            <div className="solutions-content-item">
              <div className="solutions-content-item-feature">Feature 1</div>
              <div style={{ flex: 1 }}>
                <div className="solutions-content-item-title">
                  Ai-Powered Resume Optimization
                </div>
                <div className="solutions-content-item-content">
                  <div className="solutions-content-li">
                    <div className="solutions-content-li-title">
                      Precision optimization
                    </div>
                    <div>
                      Leverages natural language processing to analyze resume
                      content, identify areas for improvement, and intelligently
                      optimize based on target job requirements.
                    </div>
                  </div>
                  <div className="solutions-content-li">
                    <div className="solutions-content-li-title">
                      Personalized Suggestions
                    </div>
                    <div>
                      Provides customized recommendations tailored to the user's
                      industry, role, and background to boost resume success
                      rates.
                    </div>
                  </div>
                  <div className="solutions-content-li">
                    <div className="solutions-content-li-title">
                      Keyword Matching
                    </div>
                    <div>
                      Optimizes resume keywords for better compatibility with
                      companies' ATS (Applicant Tracking Systems).
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="solutions-content-item">
              <div className="solutions-content-item-feature">Feature 2</div>
              <div style={{ flex: 1 }}>
                <div className="solutions-content-item-title">
                  AI-Automated Resume Submission{" "}
                  <span style={{ fontWeight: 400, fontSize: 14 }}>
                    {" "}
                    (LinkedIn / Indeed)
                  </span>
                </div>
                <div className="solutions-content-item-content">
                  <div className="solutions-content-li">
                    <div className="solutions-content-li-title">
                      Batch Submission
                    </div>
                    <div>
                      Leverages natural language processing to analyze resume
                      content, identify areas for improvement, and intelligently
                      optimize based on target job requirements.
                    </div>
                  </div>
                  <div className="solutions-content-li">
                    <div className="solutions-content-li-title">
                      Job Matching
                    </div>
                    <div>
                      Intelligently filters job postings highly relevant to the
                      user's profile, maximizing submission efficiency.
                    </div>
                  </div>
                  <div className="solutions-content-li">
                    <div className="solutions-content-li-title">
                      Real-Time Feedback
                    </div>
                    <div>
                      Tracks submission results, analyzes performance, and
                      provides strategy adjustments for future applications.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="solutions-content-item">
              <div className="solutions-content-item-feature">Feature 3</div>
              <div style={{ flex: 1 }}>
                <div className="solutions-content-item-title">
                  Custom Question Bank for AI Interview Training
                </div>
                <div className="solutions-content-item-content">
                  <div className="solutions-content-li">
                    <div className="solutions-content-li-title">
                      Tailored Training
                    </div>
                    <div>
                      Tracks submission results, analyzes performance, and
                      provides strategy adjustments for future applications.
                    </div>
                  </div>
                  <div className="solutions-content-li">
                    <div className="solutions-content-li-title">
                      Dynamic Optimization
                    </div>
                    <div>
                      Continuously updates interview strategies based on
                      performance in each training session, offering guidance
                      that aligns with user needs.
                    </div>
                  </div>
                  <div className="solutions-content-li">
                    <div className="solutions-content-li-title">
                      Industry Coverage
                    </div>
                    <div>
                      Supports question bank imports across various industries,
                      ensuring compatibility with professional interview
                      scenarios.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="solutions-content-item">
              <div className="solutions-content-item-feature">Feature 4</div>
              <div style={{ flex: 1 }}>
                <div className="solutions-content-item-title">
                  AI Mock Interview Assistant
                </div>
                <div className="solutions-content-item-content">
                  <div className="solutions-content-li">
                    <div className="solutions-content-li-title">
                      Real-Time Support
                    </div>
                    <div>
                      Provides instant analysis and answer suggestions during
                      real interviews.
                    </div>
                  </div>
                  <div className="solutions-content-li">
                    <div className="solutions-content-li-title">
                      Role-Playing
                    </div>
                    <div>
                      Allows users to practice "cracking tough questions" with
                      the AI, enhancing confidence and adaptability.
                    </div>
                  </div>
                  <div className="solutions-content-li">
                    <div className="solutions-content-li-title">
                      Performance Analytics
                    </div>
                    <div>
                      Records and analyzes each session, offering targeted
                      improvement suggestions for future interviews.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>Fast pass for security penetration test</div>
            <div className="solutions-content-btn">
              <span>Subscribe the AI assistant</span>
              <img src={require("./black-arrow.png")} alt="icon" />
            </div>
          </div>
        </div>
        <div>
          <div></div>
          <img src={require("./img-03.jpg")} alt="" />
        </div>
      </div>
    </section>
  );
};

export default SolutionsResume;
