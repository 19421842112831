import React, { FC } from "react";
import useWindowSize from "src/hooks/useWindowSize";
import styles from "./index.module.less";
import AnnaButton from "src/components/button";

interface Props {}

const SolutionsBanner: FC<Props> = () => {
  const { isMobile } = useWindowSize();

  return (
    <section
      className={styles["solutions-banner"]}
      style={{ paddingLeft: isMobile ? 20 : 160 }}
    >
      <div className={styles["banner-content"]}>
        <div className={styles["banner-title"]}>3 in-depth services</div>
        <div className={styles["banner-subtitle"]}>
          Empowering Your Business with
          <br />
          Seamless Solutions
        </div>
        <div className={styles["banner-desc"]}>
          Whether you need tailored IT systems, advanced security testing, or
          AI-driven career tools, we’re here to elevate
          <br />
          your success. Our expert team combines innovative technology with
          personalized service to deliver reliable
          <br />
          solutions that meet your unique needs. Let’s build, secure, and grow
          together.
        </div>
        <div className={styles["banner-btns"]}>
          <AnnaButton type="primary">Contact Us for Free</AnnaButton>
        </div>
      </div>
    </section>
  );
};

export default SolutionsBanner;
