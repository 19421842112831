import React, { FC } from "react";
import styles from "./index.module.less";

interface Props {
  children?: React.ReactNode;
  type?: "primary" | "outline";
  style?: React.CSSProperties;
  onClick?: () => void;
}

const AnnaButton: FC<Props> = ({
  children,
  onClick = () => {},
  type = "primary",
  style = {},
}) => {
  return (
    <div
      className={styles["anna-button"] + " " + styles[type]}
      style={style}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default AnnaButton;
