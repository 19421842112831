import { Layout } from "@arco-design/web-react";
import styles from "./index.module.less";
import logo from "src/assets/pngs/logo.png";
import locale from "./locale";
import useLocale from "src/utils/useLocale";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const t = useLocale(locale);
  const navigate = useNavigate();
  return (
    <Layout.Footer className={styles["footer"]}>
      <div className={styles["footer-menu"]}>
        <div className={styles["logo"]}>
          <img
            style={{
              width: "40px",
              objectFit: "cover",
              overflow: "hidden",
            }}
            alt="Anna"
            src={logo}
          />
          <div className={styles["logo-text"]}>
            <span className={styles["logo-span"]}>Anna Group</span>
          </div>
        </div>
        <div className={styles["footer-menu-list"]}>
          {["solutions", "case", "about", "price"].map((item, index) => {
            return (
              <div
                key={index}
                className={styles["footer-menu-item"]}
                onClick={() => {
                  navigate(item);
                }}
              >
                <span>{t[`footer.${item.toLowerCase()}`] || item}</span>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles["footer-copyright"]}>
        <div>
          <span style={{ marginRight: 16 }}>© 2024 Anna Group</span>
          <span style={{ marginRight: 16 }}>Terms</span>
          <span>Privacy (Updated 12/2024)</span>
        </div>
      </div>
    </Layout.Footer>
  );
};

export default Footer;
