import React, { FC } from "react";
import useWindowSize from "src/hooks/useWindowSize";
import styles from "./index.module.less";
import AnnaButton from "src/components/button";

interface Props {}

const AboutBanner: FC<Props> = () => {
  const { isMobile } = useWindowSize();

  return (
    <section
      className={styles["solutions-banner"]}
      style={{ paddingLeft: isMobile ? 20 : 160 }}
    >
      <div className={styles["banner-content"]}>
        <div className={styles["banner-title"]}>ABOUT ANNA GROUP</div>
        <div className={styles["banner-subtitle"]}>
          We’re bringing technical
          <br />
          superpowers to everyone.
        </div>
        <img src={require("./about-banner.png")} alt="" />
      </div>
    </section>
  );
};

export default AboutBanner;
