import React, { FC } from "react";
import useWindowSize from "src/hooks/useWindowSize";

import SolutionsBanner from "./components/solutions-banner";
import SolutionsIT from "./components/solutions-it";
import SolutionsPenetration from "./components/solutions-penetration";
import SolutionsResume from "./components/solutions-resume";

interface Props {}

const Solutions: FC<Props> = () => {
  const { isMobile } = useWindowSize();
  return (
    <div>
      <SolutionsBanner />
      <section style={{ padding: isMobile ? "0 20px" : "0 160px" }}>
        <SolutionsIT />
        <SolutionsPenetration />
        <SolutionsResume />
      </section>
    </div>
  );
};

export default Solutions;
