import React from "react";
import "./index.less";

const AboutVersion = () => {
  return (
    <div className="about-version">
      <div style={{ padding: "80px 0" }}>
        <div style={{ fontSize: 16 }}>VERSION</div>
        <div style={{ fontSize: 48, fontWeight: 600 }}>
          Our vision is to enable <br /> developers to create software <br />
          that advances humanity.
        </div>
        <div className="version-item">
          <div style={{ flex: 1, fontSize: 48, fontWeight: 600 }}>
            Core Values
          </div>
          <div style={{ flex: 1 }}>
            <div style={{ fontSize: 32, fontWeight: 600 }}>Innovation</div>
            <div style={{ marginBottom: 16 }}>
              Utilizing cutting-edge technology to drive business growth and
              innovation.
            </div>
            <div style={{ fontSize: 32, fontWeight: 600 }}>Customer Focus</div>
            <div style={{ marginBottom: 16 }}>
              Providing high-value-added services with a strong commitment to
              client satisfaction.
            </div>
            <div style={{ fontSize: 32, fontWeight: 600 }}>Trust</div>
            <div style={{ marginBottom: 16 }}>
              Building strong relationships with clients through reliable
              development capabilities.
            </div>
            <div style={{ fontSize: 32, fontWeight: 600 }}>Collaboration</div>
            <div style={{ marginBottom: 16 }}>
              Evolving as a team to continuously meet the evolving needs of our
              customers.
            </div>
          </div>
        </div>
        <div className="version-item">
          <div style={{ flex: 1, fontSize: 48, fontWeight: 600 }}>
            Backed by
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              fontSize: 32,
              fontWeight: 600,
              flexWrap: "wrap",
            }}
          >
            <div style={{ flexBasis: "50%" }}>Hitachi</div>
            <div style={{ flexBasis: "50%" }}>Sony</div>
            <div style={{ flexBasis: "50%", marginTop: 42 }}>SoftBank</div>
            <div style={{ flexBasis: "50%", marginTop: 42 }}>Espoirer</div>
            <div style={{ flexBasis: "50%", marginTop: 42 }}>NEC</div>
            <div style={{ flexBasis: "50%", marginTop: 42 }}>Snarkify</div>
          </div>
        </div>
        <div className="version-item">
          <div style={{ flex: 1, fontSize: 48, fontWeight: 600 }}>
            By the numbers
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              fontSize: 32,
              fontWeight: 600,
              flexWrap: "wrap",
            }}
          >
            <div style={{ flexBasis: "50%" }}>
              <div>$xxx</div>
              <div style={{ fontSize: 24 }}>In total funding</div>
            </div>
            <div style={{ flexBasis: "50%" }}>
              <div>2022</div>
              <div style={{ fontSize: 24 }}>Year founded</div>
            </div>
            <div style={{ flexBasis: "50%", marginTop: 42 }}>
              <div>6+</div>
              <div style={{ fontSize: 24 }}>Clients</div>
            </div>
            <div style={{ flexBasis: "50%", marginTop: 42 }}>
              <div>xxx</div>
              <div style={{ fontSize: 24 }}>xx</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutVersion;
