import React, { FC } from "react";
import "./index.less";

interface Props {}

const SolutionsPenetration: FC<Props> = () => {
  return (
    <section className="solutions-penetration">
      <div className="solutions-scope">Scope2</div>
      <div className="solutions-title">System Security Penetration Testing</div>
      <div className="solutions-sub-title">
        Real-World Attacks, Rock-Solid Defense.
      </div>
      <div className="solutions-content">
        <div className="solutions-content-left">
          <div className="solutions-content-left-top">
            <div className="solutions-content-item">
              <img
                className="solutions-content-item-icon"
                src={require("./icon-01.png")}
                alt="icon"
              />
              <div>
                <div className="solutions-content-item-step">Step 1</div>
                <div className="solutions-content-item-title">
                  Requirement Analysis
                </div>
                <div>
                  Discuss with clients to clarify their network security needs,
                  including testing type and assessment scope.
                </div>
              </div>
            </div>
            <div className="solutions-content-item">
              <img
                className="solutions-content-item-icon"
                src={require("./icon-02.png")}
                alt="icon"
              />
              <div>
                <div className="solutions-content-item-step">Step 2</div>
                <div className="solutions-content-item-title">
                  Free Trial Experience
                </div>
                <div>
                  We offer free testing software for clients to assess parts of
                  their system's security, helping them identify risks and
                  refine testing needs.
                </div>
              </div>
            </div>
            <div className="solutions-content-item">
              <img
                className="solutions-content-item-icon"
                src={require("./icon-03.png")}
                alt="icon"
              />
              <div>
                <div className="solutions-content-item-step">Step 3</div>
                <div className="solutions-content-item-title">
                  Final Testing Plan Confirmation
                </div>
                <div>
                  Based on free trial feedback, we adjust the testing scope,
                  depth, and intensity to achieve optimal results within the
                  client's budget.
                </div>
              </div>
            </div>
            <div className="solutions-content-item">
              <img
                className="solutions-content-item-icon"
                src={require("./icon-04.png")}
                alt="icon"
              />
              <div>
                <div className="solutions-content-item-step">Step 4</div>
                <div className="solutions-content-item-title">
                  Hacker-Level Security Attack Testing
                </div>
                <div>
                  Our expert team conducts hacker-level penetration testing,
                  using the latest tools to evaluate system security and uncover
                  vulnerabilities.
                </div>
              </div>
            </div>
            <div className="solutions-content-item">
              <img
                className="solutions-content-item-icon"
                src={require("./icon-05.png")}
                alt="icon"
              />
              <div>
                <div className="solutions-content-item-step">Step 5</div>
                <div className="solutions-content-item-title">
                  Test Report and Remediation Recommendations
                </div>
                <div>
                  We provide a detailed security report listing identified
                  vulnerabilities and threats, along with practical remediation
                  advice to help clients strengthen their system’s security.
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>Fast pass for security penetration test</div>
            <div className="solutions-content-btn">
              <span>Get the Form</span>
              <img src={require("./white-arrow.png")} alt="icon" />
            </div>
          </div>
        </div>
        <div>
          <div></div>
          <img src={require("./img-02.jpg")} alt="" />
        </div>
      </div>
    </section>
  );
};

export default SolutionsPenetration;
