import { FC } from "react";
import "./index.less";
import useWindowSize from "src/hooks/useWindowSize";

interface Props {}

const Price: FC<Props> = () => {
  const { isMobile } = useWindowSize();
  return (
    <div>
      <section style={{ padding: isMobile ? "80px 20px" : "80px 160px" }}>
        <div className="price-intro" style={{ borderBottom: "1px solid #000" }}>
          Get the best service and platform.
        </div>
        <div className="price-intro" style={{ marginTop: 24 }}>
          AI Resume & Interview Assistant
        </div>
        <div className="price-content">
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                fontWeight: "600",
              }}
            >
              <span style={{ fontSize: 32, marginLeft: 8 }}>19.99 USD</span>
              <span
                style={{
                  fontSize: 16,
                  position: "relative",
                  top: -8,
                  marginLeft: 8,
                }}
              >
                per user / month
              </span>
            </div>
            <div>
              <img src={require("./wechat.png")} alt="" />
            </div>
          </div>
          <div>
            <div style={{ fontSize: 20, marginBottom: 24, fontWeight: "600" }}>
              What’s included:
            </div>

            <ul className="price-desc">
              <li className="price-item-desc">
                <img src={require("./icon.png")} alt="icon" />
                <span>AI-powered resume optimization</span>
              </li>
              <li className="price-item-desc">
                <img src={require("./icon.png")} alt="icon" />
                <span>AI-Automated Resume Submission</span>
              </li>
              <li className="price-item-desc">
                <img src={require("./icon.png")} alt="icon" />
                <span>
                  Custom Question Bank for AI Interview <br /> Training
                </span>
              </li>
              <li className="price-item-desc">
                <img src={require("./icon.png")} alt="icon" />
                <span>AI Mock Interview Assistant</span>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Price;
