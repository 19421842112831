import React, { FC } from "react";
import "./index.less";

interface Props {}

const CaseAgency: FC<Props> = () => {
  return (
    <div className="case-content">
      <div className="case-content-item">
        <div className="case-content-item-left">
          <div className="case-content-item-desc">The attack success rate</div>
          <div className="case-content-item-title">
            The attack success rate dropped, greatly lowering the chances of
            actual success.
          </div>
          <div className="case-content-item-subtitle">Case Background</div>
          <div>
            A large e-commerce platform faced increasing complexity in its
            network environment and growing risks of potential attacks due to
            business expansion. Operating across multiple countries and handling
            millions of daily transactions and customer data, ensuring high
            system security and sustainable operations was critical. Anna
            Group’s penetration testing service provided a comprehensive
            security diagnosis, helping the platform identify and fix
            vulnerabilities to safeguard its business operations.
          </div>
          <div className="case-content-item-subtitle">Testing Process</div>
          <div>
            Anna Group collaborated closely with the client to thoroughly
            understand their network structure and security needs. A free
            initial test was offered to demonstrate the value of penetration
            testing. After client approval, advanced penetration testing tools
            and hacker-grade techniques were used to deeply analyze potential
            risks in web applications and the platform. The process included:
            <div style={{ marginTop: 24 }}>1. Vulnerability Identification</div>
            <div>2. Simulated Attacks</div>
            <div>3. Data Analysis</div>
            <div>4. Report Generation</div>
          </div>
          <div className="case-content-item-subtitle">
            Results and Improvement Measures
          </div>
          <div>
            The testing revealed multiple high-risk and medium-risk
            vulnerabilities, including SQL injection, XSS flaws, and weak
            password configurations. Anna Group provided detailed remediation
            recommendations and optimization strategies for each risk.
            <ul style={{ marginTop: 24 }}>
              <li>High-Risk Vulnerabilities Fixed: 22</li>
              <li>Medium-Risk Vulnerabilities Fixed: 15</li>
              <li>
                Post-Remediation Review: Ensured the effectiveness of fixes.
              </li>
            </ul>
          </div>
          <div className="case-content-item-subtitle">Project Impact</div>
          <div>Improved Security: </div>
          <div>
            After remediation, the system passed stricter security audits, with
            its overall security score improving from 75 to 92, significantly
            boosting client confidence.
          </div>
          <div style={{ marginTop: 24 }}>Reduced Attack Success Rate: </div>
          <div>
            Simulated attack success rates dropped by 50%, greatly lowering the
            likelihood of real-world attack success.
          </div>
          <div style={{ marginTop: 24 }}>Enhanced Business Continuity: </div>
          <div>
            Upgraded protection mechanisms reduced network failure rates by 35%,
            ensuring stable operations during peak periods.
          </div>
          <div style={{ marginTop: 24 }}>Increased Customer Satisfaction: </div>
          <div>
            The project effectively reduced security incidents, enhancing client
            trust and satisfaction while lowering business risk costs by 20%.
          </div>
          <div className="case-content-item-subtitle">Client Feedback</div>
          <div>
            "Anna Group’s penetration testing service not only helped us uncover
            hidden security vulnerabilities but also provided professional
            guidance to optimize our cybersecurity strategies. This
            collaboration not only improved our platform’s security but also
            laid a solid foundation for our future business expansion."
          </div>
        </div>
        <img
          src={require("./img-01.png")}
          alt="img-01"
          style={{ position: "sticky", top: 100 }}
        />
      </div>
      <div className="case-content-item">
        <div className="case-content-item-left">
          <div className="case-content-item-desc">
            the impact of Interview Opportunities
          </div>
          <div className="case-content-item-title">
            AI job assistant users saw a significant increase in invitations,
            with many landing interviews at top companies.
          </div>
          <div className="case-content-item-subtitle">Case Background</div>
          <div>
            Two universities in Dalian and a job training organization for U.S.
            international students adopted Anna Group's AI Job Assistant to help
            students better prepare for the job search process, from resume
            optimization to interview practice, ensuring a smooth transition
            from campus to career.
          </div>
          <div className="case-content-item-subtitle">
            Client Needs and Challenges
          </div>
          <div>
            <div>Improved Resume Quality: </div>
            <div>
              Many students’ resumes lacked impact and failed to effectively
              showcase their skills and experience, making it difficult to stand
              out during the initial screening.
            </div>
            <div style={{ marginTop: 24 }}>
              Systematic Interview Preparation:
            </div>
            <div>
              Students had limited interview experience and were unfamiliar with
              interview structures and typical questions, struggling to handle
              various interview scenarios.
            </div>
            <div style={{ marginTop: 24 }}>
              Real-time Feedback and Suggestions:
            </div>
            <div>
              Students needed timely feedback during mock and real interviews to
              improve their responses and boost confidence.
            </div>
          </div>
          <div className="case-content-item-subtitle">Solution</div>
          <div>
            <div>
              Anna Group's AI Job Assistant provided an all-in-one job search
              tool, from resume optimization to interview coaching, helping
              students prepare for the job market:
            </div>
            <div style={{ marginTop: 24 }}>Resume Optimization: </div>
            <div>
              The AI assistant optimized resumes based on industry standards and
              job requirements, highlighting personal strengths, which
              significantly increased the chances of landing interviews.
            </div>
            <div style={{ marginTop: 24 }}>Automated Resume Submission:</div>
            <div>
              The AI assistant automated the submission process across major job
              platforms (e.g., LinkedIn, Indeed, BOSS Zhipin), allowing students
              to submit over 100 targeted job applications daily, boosting
              resume visibility and interview opportunities.
            </div>
            <div style={{ marginTop: 24 }}>AI Mock Interviews:</div>
            <div>
              The AI-driven mock interview system allowed students to practice
              answering common interview questions, receiving real-time analysis
              and feedback to refine their answers and communication skills.
            </div>
            <div style={{ marginTop: 24 }}>Real-time Interview Assistance:</div>
            <div>
              During actual interviews, the AI assistant provided instant
              suggestions to help students adjust their responses and better
              showcase their abilities.
            </div>
          </div>
          <div className="case-content-item-subtitle">Results and Impact</div>
          <div>
            <div>Increased Interview Opportunities: </div>
            <div>
              Students from universities and training institutions using the AI
              Job Assistant saw a significant increase in interview invitations,
              with many securing interviews at top companies.
            </div>
            <div style={{ marginTop: 24 }}>Higher Interview Success Rate:</div>
            <div>
              With the help of AI mock training and real-time coaching,
              students’ interview success rate improved by 45%, significantly
              boosting their confidence.
            </div>
            <div style={{ marginTop: 24 }}>Increased Employment Rate: </div>
            <div>
              The training institution saw a 30% increase in student employment
              rates within a year, with many international students securing
              positions at top U.S. companies and fulfilling their career goals.
            </div>
          </div>
          <div className="case-content-item-subtitle">Client Feedback</div>
          <div>
            "The AI Job Assistant not only helped our students enhance their job
            search competitiveness but also provided invaluable real-time
            feedback during actual interviews. Through systematic resume
            optimization and mock interview training, students' confidence and
            performance greatly improved. This tool has become an essential part
            of our career development center and training programs, strongly
            supporting our students' employment goals."
          </div>
        </div>
        <img
          src={require("./img-02.png")}
          alt="img-02"
          style={{ position: "sticky", top: 100 }}
        />
      </div>
    </div>
  );
};

export default CaseAgency;
